import React, { Component } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { logout } from "../Utilities/Authentication";
import logo from "../favicon.ico"

export class NavBar extends Component {

  handleLogout = (e) => {
    logout();
  }

  render() {
    return (
      <Navbar className="bg-body-secondary">
        <Container fluid>
          <Nav activeKey="/home">
                <Navbar.Brand href="#home"><img src={logo} alt="coffee cup logo" height="30" /></Navbar.Brand>
                <Nav.Link href="/home">Home</Nav.Link>
                <Nav.Link href="#home">Home</Nav.Link>
                <Nav.Link href="#home">Home</Nav.Link>
          </Nav>
        <Button size="sm" onClick={this.handleLogout}>Log out</Button>
        </Container>
      </Navbar>
    )
  }
}

export default NavBar