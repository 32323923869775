import React from 'react';
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./Utilities/ProtectedRoute";
import Landing from "./Pages/Landing";
import Home from "./Pages/Home";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import { isAuthenticated } from './Utilities/Authentication';

export default function MainRouter() {
  return (
    <div>
        <Routes>
            <Route path="/" element={
                <ProtectedRoute isAuth={isAuthenticated()}>
                    <NavBar />
                        <Routes>
                            <Route path="/" element={<Home />} />
                        </Routes>
                    <Footer />
                </ProtectedRoute>
            } />
            <Route exact path="/landing" elements={<Landing />} />
        </Routes>
    </div>
  )
}
