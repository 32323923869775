import User from "./User";
import Axios from "./Axios";
import UserDB from "./TestLoginInfo";

const LOCAL_STORAGE_STRING = "espressoSession"

export const authenticate = (user, token, cb) => {
    if (typeof window !== "undefined") {
        console.log("inside authentication, setting localStorage user and token....");
        localStorage.setItem(LOCAL_STORAGE_STRING, JSON.stringify({ email: user.email, id: user._id, token }));
        cb(true);
    } else cb(false);
}

export const isAuthenticated = () => {
    if (typeof window !== "undefined" &&
    localStorage.getItem(LOCAL_STORAGE_STRING) &&
    localStorage.getItem(LOCAL_STORAGE_STRING) !== "") {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_STRING));
    } else return false;
}

// simple fake login and signup system for testing:
export const signup = (user, cb) => {
    var result = Axios.post("signup", user)
        .then(({data}) => {
            console.log(data);
            if (data.error) {
                console.log("error received from backend: " + data.error);
                cb(null, { error: data.error });
            } else {
                authenticate(data.user, data.token, cb);
            }
        })
        .catch((err) => {
            console.log("Error in attempting signup:");
            console.log(err);
            cb(null, { error: "There was an error attempting to sign up.  Please try again."});
        })
    // UserDB.addUser(user.email, user.password);
    // authenticate(user, cb);
}

export const login = (user, cb) => {
    var result = Axios.post("login", user)
        .then(({data}) => {
            console.log(data);
            if (data.error) {
                console.log("error received from backend: " + data.error);
                cb(null, { error: data.error });
            } else {
                authenticate(data.user, data.token, cb);
            }
        })
        .catch((err) => {
            console.log("Error in attempting login:");
            console.log(err);
            cb(null, { error: "There was an error attempting to log in. Please try again."});
        });
}

export const logout = () => {
    if (typeof window !== "undefined") {
        localStorage.clear(LOCAL_STORAGE_STRING);
        window.location.reload(false);
    }
}