import User from "./User";

class TestUserDB {
    #_id = Math.random();
    #_users = [];

    addUser(email, password) {
        let newUser = new User(email, password);
        console.log("newUser: ");
        console.log(newUser);
        this.#_users.push(newUser);
        console.log("user added. to _users for UserDB " + this.#_id + ": ");
        console.log(this.#_users);
    }

    getUsers() {
        return this._users;
    }

    validateUser(user) {
        for (let i = 0; i < this.#_users.length; i++) {
            if (user.email === this.#_users[i].email && user.password === this.#_users[i].password) {
                console.log("user match found...");
                return true;
            }
        }
        console.log("returning false...");
        return false;
    }
}

const UserDB = new TestUserDB();
UserDB.addUser("founder@company.com", "123456");
export default UserDB;