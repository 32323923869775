import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { login } from "../Utilities/Authentication";
import User from "../Utilities/User";
import './modals.css';

export class LoginForm extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            error: "",
            redirectToHome: false,
            loading: false,
            userId: null,
        }
    }

    handleChange = (e) => {
        this.setState({error: ""});
        this.setState({ [e.target.name]: e.target.value});
        console.log("email: " + this.state.email + ", password: " + this.state.password);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted email: " + this.state.email + " and password: " + this.state.password);
        
        // for testing purposes only:
        login(new User(this.state.email, this.state.password), (success, error) => {
            if (error) {
                this.setState({ error: error.error });
                return;
            }
            if (success) {
                console.log("login successful...");
                this.setState({ redirectToHome: true });
                window.location.reload(false);
            }
        });
    }

    handleClose = () => {
        this.props.handleCloseModal();
    }

  render() {
    if (this.state.redirectToHome) {
        return <Navigate to={'/'} />;
    }

    return (
        <Form onSubmit={this.handleSubmit}>
            <Modal.Body className="bg-dark white-text">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                type="email" 
                name="email"
                placeholder="enter your email" 
                className="bg-dark"
                defaultValue={this.state.email} 
                onChange={this.handleChange}
                autoFocus/>
            </Form.Group>
            <Form.Group className="mb-0" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control 
                type="password" 
                name="password"
                placeholder="enter your password"
                defaultValue={this.state.password}
                onChange={this.handleChange} 
                className="bg-dark"/>
            </Form.Group>
            </Modal.Body>
            <Modal.Footer className="bg-dark">
                <Button variant="warning" size="sm" type="submit">Log In</Button>
            </Modal.Footer>
        </Form>
    )
  }
}

export default LoginForm