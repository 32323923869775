import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LoginForm from "../Components/LoginForm";
import SignupForm from "../Components/SignupForm";
import Footer from '../Components/LandingFooter';
import logo from '../espresso-logo-black-bg.png';
import './Landing.css';

const ModalType = Object.freeze({
    Login:"login",
    Signup: "signup"
})

class Landing extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            modalType: null,
        }
    }
    
    handleShowLogin = (e) => {
        this.setState({show: true, modalType: ModalType.Login});
    }

    handleShowSignup = (e) => {
        this.setState({show: true, modalType: ModalType.Signup});
    }

    handleCloseModal = (e) => {
        this.setState({show: false, modalType: null});
    }


    render() {
        return (
            <div className="landing-page">
                <div className="hero">
                    <img src={logo} className="hero-logo" alt="logo" />
                    <h5 className="hero-tagline">
                        A better cap table and
                        <br/>
                        liquidation waterfall platform.
                    </h5>
                <Button variant="secondary" onClick={this.handleShowLogin}>Log Me In</Button>
                <div className="padding-6" />
                <div className="padding-6" />
                <h6 className="small-text">New to espresso?</h6>
                <div className="padding-2" />
                <Button variant="warning" size="sm" onClick={this.handleShowSignup}>Sign Me Up</Button>
                </div>
                <Modal show={this.state.show} onHide={this.handleCloseModal} centered>
                    {this.state.modalType === ModalType.Login &&<LoginForm handleCloseModal={this.handleCloseModal} />}
                    {this.state.modalType === ModalType.Signup &&<SignupForm handleCloseModal={this.handleCloseModal} />}
                </Modal>
            <Footer />
            </div>  
        )
    }
}

export default Landing;