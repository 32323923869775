import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { signup } from '../Utilities/Authentication';
import User from "../Utilities/User";
import './modals.css';

export class SignupForm extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            accepted: false,
            error: "",
            redirectToHome: false,
            loading: false,
            userId: null,
        }
    }

    handleChange = (e) => {
        this.setState({ error: "" });
        this.setState({ [e.target.name]: e.target.value });
        console.log("email: " + this.state.email + ", password " + this.state.password);
    }

    handleCheckboxChange = (e) => {
        console.log("Changed: " + e.target.name + " to value: " + e.target.checked);
        this.setState({ [e.target.name]: e.target.checked });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.accepted) return;


        // for testing purposes:
        const user = { email: this.state.email, password: this.state.password };
        signup(user, (success, error) => {
            if (error) {
                this.setState({ error: error.error });
                return;
            }

            if (success) {
                this.setState({ redirectToHome: true });
                window.location.reload(false);
            }
        });
    }

    handleClose = () => {
        this.props.handleCloseModal();
    }

  render() {
    if (this.state.redirectToHome) {
        return <Navigate to={'/'} />;
    }
    return (
        <Form onSubmit={this.handleSubmit}>
            <Modal.Body className="bg-dark white-text">
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email"
                name="email"
                defaultValue={this.state.email} 
                onChange={this.handleChange}
                placeholder="enter your email" 
                className="bg-dark" 
                autoFocus />
                <Form.Text className="white-text" size="sm">We'll never share your email with anyone else.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" 
                name="password"
                defaultValue={this.state.password}
                onChange={this.handleChange}
                placeholder="enter your password" 
                className="bg-dark" />
                <Form.Text className="white-text">At least 6 characters w/a capital letter, lowercase letter & number/symbol.</Form.Text><br></br>
            </Form.Group>
            <Form.Group className="mb-0" controlId="formBasicCheckbox">
                <Form.Check 
                type="checkbox" 
                name="accepted"
                onChange={this.handleCheckboxChange}
                defaultValue={this.state.accepted}
                label={<span>
                    I agree to the <a href="#terms">terms and conditions</a>.
                    </span>}
                    />
            </Form.Group>
            </Modal.Body>
            <Modal.Footer className="bg-dark white-text">
            <Button variant="warning" size="sm" type="submit">Sign Up</Button>
            </Modal.Footer>
        </Form>
    )
  }
}

export default SignupForm