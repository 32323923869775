import React from 'react'
import Landing from "../Pages/Landing";


const ProtectedRoute = ({ isAuth, children }) => {

    if (!isAuth) {
        return <Landing />;
    }
    return children;
}

export default ProtectedRoute